<template lang="pug">
  .h-100.w-100.position-absolute
    #map-search-condition.position-absolute.m-2.shadow-sm
      .input-group(v-if="isDispSearchControl")
        input#search-text.form-control(type="text" placeholder="キーワード" @keydown.enter="searchRockyList")
        .input-group-append
          button#search-button.input-group-text(@click.prevent="searchRockyList")
            i.fa.fa-search
      //-検索結果
      #searchResult.bg-light
    //-登攀スタイル
    #style-condition.position-absolute.pt-3(v-if="isDispSearchControl")
      //-モバイル
      .btn-group-mobile.btn-group-vertical(role="group" aria-label="登攀スタイル")
        button.btn.btn-light#style-all(type="button" v-bind:class="{styleActive:isStyleAll}" @click.prevent="changeStyle('all')" ) 全て
        button.btn.btn-light#style-bl(type="button" v-bind:class="{styleActive:isStyleBl}" @click.prevent="changeStyle('bl')") ボルダー
        button.btn.btn-light#style-rc(type="button" v-bind:class="{styleActive:isStyleRc}" @click.prevent="changeStyle('rc')") ロープ
      //-PC
      .btn-group(role="group" aria-label="登攀スタイル")
        button.btn.btn-light#style-all(type="button" v-bind:class="{styleActive:isStyleAll}" @click.prevent="changeStyle('all')" ) 全て
        button.btn.btn-light#style-bl(type="button" v-bind:class="{styleActive:isStyleBl}" @click.prevent="changeStyle('bl')") ボルダー
        button.btn.btn-light#style-rc(type="button" v-bind:class="{styleActive:isStyleRc}" @click.prevent="changeStyle('rc')") ロープ
    //-拡大表示ボタン
    .position-absolute#expand-button(v-if="isCustumExpandCtlShow")
      button(@click.prevent="mapFull")
        //-広げるマークと閉じるマーク
        i.fas(v-bind:class="[isExpanded === true ? 'fa-compress-arrows-alt' : 'fa-expand-arrows-alt']")
    //-岩場ダイアログ
    .position-absolute#mapRockyDrawer(v-if="isDispMapRockyDrawer")
      #mapRockyDrawer-inner
        #rocky-img
          #rocky-img-inner
            img(:src="makeImgPath(drawerRockyImgPath)" :alt="drawerRockyName")
            #pc-close-button
              i.fas.fa-times(@click.prevent="closeMapRockyDrawer")
        #detail
          #rockyNameKana {{drawerRockyNameKana}}
          #top.d-flex
            #rocky-name 
              a(@click="callRockyInfoFromDialog(drawerRockyId)") {{drawerRockyName}}
            #close-button
              i.fas.fa-times(@click.prevent="closeMapRockyDrawer")
          #place {{drawerRockyPlace}}
    #map.h-100
</template>

<script>
import axios from 'axios'
var scriptjs = require("scriptjs")

export default {
  // 呼び出し元から受け取るプロパティ
  props: {
    //初期位置(デフォルトの中心地点は関東あたり)
    lat: {type: Number,default: 36.180669},
    lng: {type: Number,default: 138.651111},
    zoom: {type: Number,default: 6},
    //MAPのズームプロパティ
    //greedy：PC：スクロールでズーム モバイル：指1本のスクロールで地図移動
    //cooperative：PC：Ctrl+スクロールでズーム モバイル：指2本のスクロールで地図移動
    //auto:自動設定  none:スクロール無効
    gestureHandling:{type: String, default: 'cooperative'},
    //親画面から受け取るリスト
    markerListFromParent: {type: Array,default: () => []},
    //MAP上のコントロールを配置するかどうかのフラグ
    isSearchCtlShow: {type: Boolean,default:false},
    isCustumExpandCtlShow: {type: Boolean,default:false},
    isCsSpaCtlShow: {type: Boolean,default:false},
    isReturnRockyControlShow: {type: Boolean,default:false},
    isfullscreenControlShow: {type: Boolean,default:true},
    //親画面が岩場詳細画面の場合true
    isRockyInfo: {type: Boolean,default:false},
    //親画面が岩詳細画面の場合true
    isRockInfo: {type: Boolean,default:false},
    rockId: {type: Number,default: 0},
    rockName: {type: String, default: ''},

  },
  data() {
    return {
      isDispSearchControl: false,
      isDispMapRockyDrawer: false,
      // デフォルト値
      map: null,
      // 表示エリアの自動調整用object
      bounds:null,
      // TOP画面のマーカークラスター用
      markerClusterer:null,
      // 岩の座標情報JSONファイルの値
      allRockLngLatJson:'',
      // 現在表示しているマーカーのリスト
      //マーカー生成用のデータList
      convertedRockyList:[],
      convertedRockList:[],
      //マーカーオブジェクトのList
      currentRockyMarkerList:[],//岩場
      rockyInfoRockyMarkerList:[],//岩場マーカーを再生成する際の岩場情報を保持する為のList
      currentRockMarerList:[],  //岩(TOP画面の場合は使わない)
      currentCsSpaMarkerList:[],//コンビニ・温泉(TOP画面の場合は使わない)
      // 表示中のInfowindowを保持   
      currentInfoWindow:null,
      // マーカーのアイコン
      ROCKY_ICON:process.env.VUE_APP_MATERIAL_URL + '/img/icon/icon-rock.png',
      ROCKY_SELECTED_ICON:process.env.VUE_APP_MATERIAL_URL + '/img/icon/icon-rock-selected.png',
      ROCK_ICON:process.env.VUE_APP_MATERIAL_URL + '/img/icon/icon_rock_point.png',
      ROCK_SELECTED_ICON:process.env.VUE_APP_MATERIAL_URL + '/img/icon/icon_rock_point_green_selected.png',
      CS_ICON:process.env.VUE_APP_MATERIAL_URL + '/img/icon/icon-cs.png',
      SPA_ICON:process.env.VUE_APP_MATERIAL_URL + '/img/icon/icon-spa.png',
      MY_LOCATION_ICON:process.env.VUE_APP_MATERIAL_URL + '/img/icon/my_location_point.png',
      // フラグ制御に使用
      FLG_ROCKY:'rocky',
      FLG_ROCK:'rock',
      FLG_CS_SPA:'cs_spa',
      // クラスターのアイコン
      CLUSTERER_IMG_PATH:'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      // MAP上に表示するドロワーの値
      drawerRockyId: 0,
      drawerRockyName: '',
      drawerRockyNameKana: '',
      drawerRockyPlace: '',
      drawerRockyImgPath: '',
      isExpanded:false,
      isMyLocationShow:false,
      // 登攀スタイル
      isStyleAll:true,
      isStyleBl:false,
      isStyleRc:false
    }
  },
  created() {
    // console.log('Mapコンポーネントのcreated')
    //GoogleMapAPI呼び出し
    scriptjs(
      'https://maps.googleapis.com/maps/api/js?key=' + process.env.VUE_APP_GOOGLE_MAPS_API_KEY + '&libraries=geometry&region=JP&language=ja',
      'loadGoogleMap'
    )
    //マップロード処理
    scriptjs.ready("loadGoogleMap", this.loadMap)
  },
  mounted() {
    // console.log('Mapコンポーネントのmounted')
    this.$nextTick(() => {
      // ビュー全体がレンダリングされた後にのみ実行されるコード
      // console.log('MapコンポーネントのnextTick')
    })
  },
  // データ変更後
  updated() {
    // console.log('Mapコンポーネントのupdated')
  },
  // インスタンス破棄前
  beforeDestroy() {
    // console.log('MapコンポーネントのbeforeDestroy')
  },
  // メソッド
  methods: {
    //-------------------------------------
    // MAPのロード処理(TOP画面、地図検索画面、岩画面共通)
    //-------------------------------------
    async loadMap() {

      //岩場情報JSON取得
      await this.getRockyMapData()
      //岩の座標JSON取得
      await this.getRockLongLatJson() 
      //親画面から座標データを受け取った場合はそれを表示する
      let drawMarkerList = null
      if(this.markerListFromParent.length > 0){
        drawMarkerList = this.markerListFromParent
      }else{
        drawMarkerList =  this.allRockyJsonData
      }

      //マップ表示用のListにセットする
      let paramList = []
      drawMarkerList.forEach((target) => {
        paramList.push({
          longLat: target.longitudelatitude,
          title: target.name,
          subtitle: target.level,
          containt: target.address,
          url: target.url
        })
      })

      let wkLat = this.lat
      let wkLng = this.lng
      let wkZoom = this.zoom

      //ブラウザバックした際に前回の中央位置とzoomを再現する。
      if(localStorage.isLastMapCentervalid == 'true'){
        wkLat = Number(localStorage.mapCenterLat)
        wkLng = Number(localStorage.mapCenterLng)
        wkZoom = Number(localStorage.mapCenterZoom)
      }

      //mapオブジェクト作成
      console.log('new google.maps.Map()')
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: wkLat, lng: wkLng },
        zoom: wkZoom,
        gestureHandling: this.gestureHandling, //スクロール設定
        mapTypeControl: false, //マップタイプ(地図、航空写真) コントロール
        fullscreenControl: this.isfullscreenControlShow, //全画面表示コントロール
        streetViewControl: false, //ストリートビュー コントロール
        zoomControl: false //ズーム コントロール
      })

      //this.bounds初期化
      this.initBoundsList()

      //MAP上のコントロール配置
      //検索
      if(this.isSearchCtlShow){
        this.makeSearchControl()
      }
      //コンビニ・温泉表示ボタン
      if(this.isCsSpaCtlShow){
        this.makeCsSpaControl()
      }
      //岩場に戻るボタン
      if(this.isReturnRockyControlShow){
        this.makeReturnRockyControl()
      }

      //現在位置表示ボタンは常に表示する
      this.makeMyLocationControl()

      // マーカーを表示する
      if(this.isRockyInfo){
        //岩場画面の場合
        //岩場、岩それぞれのmarkerオブジェクトリストを作成する
        this.setMarkerObjectList(drawMarkerList) 
        //マーカー作成      
        this.addRockyMarkerForRockyInfo(paramList,this.ROCKY_ICON)
        //岩場詳細画面の場合はここで終了
        return
      }else if(this.isRockInfo){
        //岩画面の場合
        //岩のマーカーを表示する
        await this.setMarkerObjectList(drawMarkerList)

        if(this.convertedRockList.length == 0){
          //岩の座標が存在しない場合は岩場のマーカーを表示する
          this.addRockyMarkerForRockyInfo(paramList,this.ROCKY_ICON)
        }else{
          await this.addRockMarker(this.$route.params.rockyId)
          this.changeRockMarkerActive(this.$route.params.rockId,this.rockName)
        }

      }else{
        //TOP画面の場合
        this.addRockyMarker(paramList,true,false,false)
      }

      // //googleMap上のズーム変更のイベントリスナー
      // google.maps.event.addListener(this.map, "zoom_changed", () => {
      // })

    },
    //----------------------------------------
    // 岩場マーカーの作成(TOPページから呼ばれる)
    //----------------------------------------
    addRockyMarker(paramList,isUseMarkerClusterer,isFitBounds,isMapPinStabilization){

      //マーカー追加
      this.addMarker(paramList,false,this.ROCKY_ICON,this.FLG_ROCKY,isFitBounds)

      //イベントリスナー設定
      this.currentRockyMarkerList.forEach((marker) => {
        marker.addListener("click", () => {

          //表示中の岩場マーカーを一旦"未選択"の状態にする
          this.currentRockyMarkerList.forEach((targetMarker) => {
            targetMarker.setIcon(new google.maps.MarkerImage(this.ROCKY_ICON))
          })

          //選択中のマーカーに変更する
          marker.setIcon(new google.maps.MarkerImage(this.ROCKY_SELECTED_ICON))

          //drawerを表示する
          this.openmapRockyDrawer(marker.id)
        })
      })

      //MarkerClustererの設定
      if(isUseMarkerClusterer){
        this.markerClusterer = new MarkerClusterer(
                                  this.map, 
                                  this.currentRockyMarkerList, 
                                  {maxZoom: 12, 
                                  imagePath: this.CLUSTERER_IMG_PATH }
                                )
      }

      if(isMapPinStabilization){
        //Map内の岩場検索時、ピンの位置ずれを修正する
        let addLong = 0
        let addLat = 0
        let count = paramList.length

        for (let index = 0; index < count; index++) {
          const element = paramList[index]
          let long = Number(element.longLat.split(',')[1])
          let lat = Number(element.longLat.split(',')[0])

          addLong += long
          addLat += lat
        }

        this.setCenter(addLat/count,addLong/count)
      }

    },
    //---------------------------------------
    // 地図を拡大表示(TOP画面から呼ばれる)
    //---------------------------------------
    mapFull(){
      if(this.isExpanded){
        this.isExpanded = false
        //親画面側でサイズを変更する
        this.$parent.mapFullReset()  
      }else{
        this.isExpanded = true
        //親画面側でサイズを変更する
        this.$parent.mapFull()     
      }
    },
    //---------------------------------------
    // 現在位置の表示
    //---------------------------------------
    async myLocationShow(){

      let pos
      try {
        //現在位置の座標取得
        pos = await this.getPosition()
        let lat = pos.coords.latitude
        let lng = pos.coords.longitude
        //for debug
        // lat = 35.64724645414717
        // lng = 139.67675525888234      

        //既にマーカー表示済みの場合は表示しない。
        if(!this.isMyLocationShow){
          //MAPにマーカーを追加する
          let marker = new google.maps.Marker({ 
            //google.maps.Markerの必須項目
            position:new google.maps.LatLng(lat,lng),
            icon: this.MY_LOCATION_ICON,
            map: this.map
          })
        }

        this.isMyLocationShow = true

        //現在位置をMAPの中心位置にする
        this.setCenter(lat,lng)

      } catch (err) {
        console.log(err)
        await this.asyncDialog('現在地を取得できませんでした。端末、もしくはブラウザの位置情報の許諾に関する設定をご確認ください。')
        return
      }
    },
    //座標取得処理
    getPosition: () => new Promise((resolve, reject) => {
      if (!navigator || !navigator.geolocation) reject()
      navigator.geolocation.getCurrentPosition(
        pos => resolve(pos),
        err => reject(err),
        {
          enableHighAccuracy: true,
          maximumAge: 300000,  // 5分前まで
          timeout: 5000
        }
      )
    }),
    //---------------------------------------
    // 岩場マーカーの作成(岩場画面から呼ばれる)
    //---------------------------------------
    async addRockyMarkerForRockyInfo(markerList,iconPath){

      //マーカーリストを初期化
      this.$parent.isRockMakerShowOnMap = false
      //岩場のマーカーを表示+currentRockyMarkerListにセット
      this.addMarker(this.convertedRockyList,false,this.ROCKY_ICON,this.FLG_ROCKY,false)

      //イベントリスナー設定
      this.currentRockyMarkerList.forEach((marker) => {

        let infowindow = this.makeInfoWindow(marker.contentString)
        // マーカークリックのイベントリスナー
        marker.addListener("click", () => {
          // 岩場マーカー押下時の処理
          this.pushRockyMarkerProcess(marker.id)
        })
        //MAPの中心位置を岩場にする
        this.setCenter(marker.position.lat(),marker.position.lng())
      })
    },
    //--------------------------
    // 岩場マーカー押下時の処理
    //--------------------------
    pushRockyMarkerProcess(rockyId){
      //岩のマーカーを表示する
      this.addRockMarker(rockyId)
      //岩場に戻るボタンを表示する
      this.makeReturnRockyControl()
      //親画面のフラグを立てる(岩場画像を非表示にする)
      this.$parent.isRockyImgShow = false
      //コンビニ・温泉ボタンを非表示にする
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].clear()
    },
    //--------------------------
    // 岩マーカー押下時の処理
    //--------------------------
    pushRockMarkerProcess(id,name){
      //親画面のメソッドを呼び出しして、ルート一覧を表示する
      //Infowindow関連のセットもこの中で実行している
      this.$parent.isRockMakerShowOnMap = true
      this.$parent.callRouteList(id,name)
    },
    //-------------------------------------------------
    // 岩マーカーを消して岩場マーカーを表示する
    //-------------------------------------------------
    addRockyMarkerAndDeleteRockMarker() {
      //岩マーカー削除
      this.currentRockMarerList.forEach(function (marker, idx) { marker.setMap(null) })
      //岩場のマーカーを生成する
      this.addRockyMarkerForRockyInfo(this.convertedRockyList,this.ROCKY_ICON)
    },
    //-------------------------------------
    // 岩マーカーの作成(岩場画面から呼ばれる)
    //-------------------------------------
    async addRockMarker(rockyId){

      this.$parent.isRockMakerShowOnMap = true

      //マーカーリストを初期化
      this.currentRockMarerList = []

      //岩の座標が1つもない場合は岩場アイコンを表示したままにする
      if(this.convertedRockList.length == 0){
        this.addRockyMarkerAndDeleteRockMarker()
        return
      }

      //コンビニ・温泉表示ボタンの削除
      if(this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER]){
        this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].clear()
      }

      //表示中のマーカーを削除
      this.deletAllMarker()

      //岩場のマーカーを表示+currentRockyMarkerListにセット
      this.addMarker(this.convertedRockList,false,this.ROCK_ICON,this.FLG_ROCK,true)

      //イベントリスナー設定
      this.currentRockMarerList.forEach((marker) => {
        let infowindow = this.makeInfoWindow(marker.contentString)

        // マーカークリックのイベントリスナー
        marker.addListener("click", () => {
          this.pushRockMarkerProcess(marker.id,marker.name)
        })
      })

    },
    //------------------------------------------------
    // 岩場、岩のマーカー用データListを作成する
    //------------------------------------------------
    async setMarkerObjectList(rockyList){
      //マーカーリストを初期化
      this.convertedRockyList = []
      this.convertedRockList = []

      //岩場のマーカーList
      //マップ表示用のListにセットする
      let wkRockyList = []
      rockyList.forEach((target) => {
        wkRockyList.push({
          longLat: target.longitudelatitude,
          title: target.name,
          subtitle: target.level,
          containt: target.address,
          url: target.rockyId,
          contentString : this.makeMarkerHtmlforRockyInfo(target.title)
        })
      })
      //マーカーObjectのListを作成してセットする（岩場）
      this.convertedRockyList = wkRockyList

      const rockyId = this.$route.params.rockyId

      //岩ののマーカーList
      //対象の岩場のデータを抽出する
      let targetRockList = this.allRockLngLatJson.filter(record => record.rpId == rockyId)
      let wkRockList = []
      targetRockList.forEach((target) => {
        //座標情報が存在する場合のみマーカーオブジェクトを生成
        if(target.lat !=null && target.lng!=null){
          wkRockList.push({
            longLat: target.lat +','+target.lng,
            url: target.id,
            title: target.name,
            contentString : this.makeMarkerHtmlforRockyInfo(target.name)
          })
        }
        
      })
      //マーカーObjectのListを作成してセットする（岩）
      this.convertedRockList = wkRockList
      return
    }, 
    //---------------------------------------------------
    //パラメータのListをマーカーObjectのリストに変換して返す
    //---------------------------------------------------
    makeMarkerObjList(paramList,isUseAnimation,iconPath){
      let returnList = []
      
      paramList.forEach((target) => {

        //マーカーオブジェクトを生成
        let marker = this.makeMarkerObject(
                      target.longLat,
                      isUseAnimation,
                      iconPath,
                      target.url,
                      target.title,
                      target.contentString)

        returnList.push(marker)
      })

      return returnList
    },
    //------------------------------
    // MAP上のマーカー作成
    //------------------------------
    addMarker(paramList,isUseAnimation,iconPath,markerType,isFitBounds){

      switch(markerType) {
        //岩場
        case this.FLG_ROCKY:
          this.currentRockyMarkerList = []  
          break;
        //岩
        case this.FLG_ROCK:
          this.currentRockMarerList = [] 
          break;
        //コンビニ・温泉
        case this.FLG_CS_SPA:
          //コンビニ、温泉の場合は同一のリストにデータを追加する為、List初期化しない。
          //this.currentCsSpaMarkerList = [] 
          break;
      }

      //this.bounds初期化
      this.initBoundsList()

      paramList.forEach((target) => {

        //マーカーオブジェクトを生成
        let marker = this.makeMarkerObject(
                      target.longLat,
                      isUseAnimation,
                      iconPath,
                      target.url,
                      target.title,
                      target.contentString)

        //MAPの表示エリア調整用のListに追加する
        let retrnArray = this.splitLngLat(target.longLat)
        this.bounds.extend(new google.maps.LatLng(retrnArray[0], retrnArray[1]))

        switch(markerType) {
          //岩場
          case this.FLG_ROCKY:
            this.currentRockyMarkerList.push(marker) 
            break;
          //岩
          case this.FLG_ROCK:
            this.currentRockMarerList.push(marker) 
            break;
          //コンビニ・温泉
          case this.FLG_CS_SPA:
            this.currentCsSpaMarkerList.push(marker)
            break;
        }

        //MAPの表示エリアを調整する
        if(isFitBounds){
          this.map.fitBounds(this.bounds)
        }
      })

    },
    //----------------------
    //Markerオブジェクト作成
    //----------------------
    makeMarkerObject(latlng,isAnimation,iconPath,id,name,contentString){

      //経度緯度を分割
      let retrnArray = this.splitLngLat(latlng)
      let lat = retrnArray[0]
      let lng = retrnArray[1]

      let wkObj = {
        //google.maps.Markerの必須項目
        position:new google.maps.LatLng(lat,lng),
        icon: iconPath,
        map: this.map,
        //独自設定項目
        id: id,
        name: name,
        contentString: contentString
      }

      //アニメーション有りの場合
      if(isAnimation){
        // 上部から1度落ちてくるアニメーションを追加する
        wkObj.animation = google.maps.Animation.DROP
      }

      return new google.maps.Marker(wkObj)
    }, 
    //-----------------------------------
    //InfoWindowオブジェクトを生成して返す
    //-----------------------------------
    makeInfoWindow(contentString){
      return new google.maps.InfoWindow({
        content: contentString,
        maxWidth: 300
      })
    },
    //-------------------------------------------------------------
    // 特定の岩IDのマーカーを強制的に選択状態して、Infowindowを表示する
    //-------------------------------------------------------------
    changeRockMarkerActive(rockId,rockName){

      //岩場マーカー削除
      this.currentRockyMarkerList.forEach(function (marker, idx) { marker.setMap(null) })
      //表示中の岩マーカーを一旦"未選択"の状態にする
      this.currentRockMarerList.forEach((targetMarker) => {
        targetMarker.setIcon(new google.maps.MarkerImage(this.ROCK_ICON))
      })

      this.currentRockMarerList.forEach((marker) => {
        // 選択した岩に対する処理
        if(marker.id == rockId){
          marker.setIcon(new google.maps.MarkerImage(this.ROCK_SELECTED_ICON))

          //表示中のInfoWindowを閉じる
          if (this.currentInfoWindow) {
            this.currentInfoWindow.close();
          }

          // 岩名がある場合のみInfoWindowをオープン状態にする
          if(marker.name != ''){
            let infowindow = this.makeInfoWindow(marker.contentString)

            this.currentInfoWindow = infowindow
            infowindow.open(this.map, marker)           
          }

          //選択した岩を中心にする
          //this.setCenter(targetMarker.position.lat(),targetMarker.position.lng())
        }
      })

      return
    },
    splitLngLat(str){
      //経度緯度を分割
      var rockyLongLat = {}
      try {
        rockyLongLat = str.split(',')
      } catch(err) {  
        //TODO:システムエラーにするか検討
        return null
      } 

      let lat = parseFloat(rockyLongLat[0])
      let lng = parseFloat(rockyLongLat[1])
      let retrnArray = [lat,lng]
    
      return retrnArray
    },
    //-------------------------------------------------
    // コンビニと温泉のマーカー作成(岩画面から呼び出される)
    //-------------------------------------------------
    addCsSpaMarker(csList,spaList) {

      // マーカー作成用のListに変換する
      let paramCsList = this.makeCsSpaList(csList,true)
      let paramSpaList = this.makeCsSpaList(spaList,false)

      //this.bounds初期化
      this.initBoundsList()
      paramCsList.forEach((target) => {this.extendBoundsList(target.longLat)})
      paramSpaList.forEach((target) => {this.extendBoundsList(target.longLat)})

      //コンビニ・温泉のマーカーを作成する
      this.addMarker(paramCsList,true,this.CS_ICON,this.FLG_CS_SPA,true)
      this.addMarker(paramSpaList,true,this.SPA_ICON,this.FLG_CS_SPA,true)

      //イベントリスナー設定
      this.currentCsSpaMarkerList.forEach((marker) => {
        let infowindow = this.makeInfoWindow(marker.contentString)
        // マーカークリックのイベントリスナー
        marker.addListener("click", () => {
          //表示中のInfoWindowを閉じる
          if (this.currentInfoWindow) {
            this.currentInfoWindow.close();
          }
          this.currentInfoWindow = infowindow
          infowindow.open(this.map, marker)
        })
      })

      //全てのコンビニ・温泉マーカーが範囲内に表示されるよう調整する
      this.map.fitBounds(this.bounds)

    },
    initBoundsList(){
      this.bounds = new google.maps.LatLngBounds()
      //常に岩場中心のマーカーが範囲に含まれるようListに追加する
      this.bounds.extend(new google.maps.LatLng(this.lat, this.lng))
    },
    extendBoundsList(lnglat){
      let retrnArray = this.splitLngLat(lnglat)
      this.bounds.extend(new google.maps.LatLng(retrnArray[0], retrnArray[1]))
    },
    makeCsSpaList(list,isCs){

      let retrnList = []
      //ルート一覧の値をセットする
      retrnList = (() => {
        let wkList = []
        let count = 0

        list.forEach((target) => {
          // 座標がセットされていない場合はListに追加しない
          if(target.longitudelatitude == undefined || target.longitudelatitude == ''){
            return
          }

          let containtStr = ''
          if(isCs){
            containtStr = '岩場から約' + target.distance + 'Km'
          }else{
            containtStr = target.info
          }
          wkList.push({
            longLat: target.longitudelatitude,
            title: target.name,
            subtitle: '',
            containt: containtStr,
            url: target.url,
            contentString : this.makeMarkerHtmlString(target.name,
                                '',containtStr,target.url)
          })
        })
        return wkList
      })()

      return retrnList
    },
    //------------------------------------------------------------
    // コンビニ、温泉のマーカー押下時に表示する吹き出しのHTMLを作成する
    //------------------------------------------------------------
    makeMarkerHtmlString(title,subtitle,containt,url) {

      let urlStr = 'GoogleMapで見る'    

      //マーカークリック時の吹き出しに表示するHTML
      let returnStr = 
      '  <div style="font-size: 1rem; font-weight:bold;">' + 
           title +
      '    <span style="color: #959EA7; text-align: right;">' + subtitle + '</span>' + 
      '  </div>'+
      '  <div style="color: #3A5263;">' + containt + '</div>'+
      '  <div>'+
      '    <a href="' + url + '" target="_blank" style="text-align: right; text-decoration: underline;">' + urlStr + '</a>'
      '  </div>'
      return returnStr
    },
    makeMarkerHtmlforRockyInfo(title){      
      //マーカークリック時の吹き出しに表示するHTML
      let returnStr = 
      '  <div style="font-size: 0.8rem; font-weight:bold;">' + 
           title +
      '  </div>'
      return returnStr

    },
    openmapRockyDrawer(rockyId){

      //岩場JSONの情報を抽出する
      let rockyJson = this.allRockyJsonData.filter(record => record.rockyId == rockyId)[0]
      this.drawerRockyId = rockyId
      this.drawerRockyName = rockyJson.name
      this.drawerRockyNameKana = rockyJson.nameKana
      this.drawerRockyPlace =  rockyJson.address
      this.drawerRockyImgPath = rockyJson.imgPath
      //drawerを表示する
      this.isDispMapRockyDrawer = true
    },
    closeMapRockyDrawer(){
      //drawer閉じる
      this.isDispMapRockyDrawer = false
    },
    //----------------------------
    //地図の中心座標を移動変更する
    //----------------------------
    setCenter(lat,lng){
      const latlng = new google.maps.LatLng(lat, lng)
      this.map.setCenter(latlng)
    },
    //----------------------------
    //岩場情報JSONファイル取得
    //----------------------------
    async getRockyMapData(){
      //岩場情報JSON
      let jsonUrl = process.env.VUE_APP_MATERIAL_URL + '/allRockyList.json'
      await axios
        .get(jsonUrl)
        .then(response => {
          this.allRockyJsonData = response.data
        })
        .catch(function (error) {alert(error)})

      //データが0件の場合
      if(this.allRockyJsonData.length == 0){
        //ここでデータが取得できない場合は、データ不正
        await this.asyncDialog('システムエラー：岩場マップ情報を取得できませんでした')
        return
      }
    },
    //--------------------------
    //岩の座標情報JSONファイル取得
    //--------------------------
    async getRockLongLatJson(){
      //岩場情報JSON
      let jsonUrl = process.env.VUE_APP_MATERIAL_URL + '/rockLongLatList.json'
      await axios
        .get(jsonUrl)
        .then(response => {
          this.allRockLngLatJson = response.data
        })
        .catch(function (error) {alert(error)})

      //データが0件の場合
      if(this.allRockLngLatJson.length == 0){
        //ここでデータが取得できない場合は、データ不正
        await this.asyncDialog('システムエラー：岩マップ情報を取得できませんでした')
        return
      }
    },
    asyncDialog(msg) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk(msg, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
        .catch(err => {
          // An error occurred
        })
      })
    },
    //---------------------------------------
    // MAP上に表示（検索コントロール）
    //---------------------------------------
    makeSearchControl(){
      this.isDispSearchControl = true

      if(document.getElementById("searchControl") != null){
        //既に表示されている場合は何もしない
        return
      }

    },
    //-----------------------------------------------------------
    //テキストボックスに入力値をキーに岩場を検索し、検索結果を表示する
    //-----------------------------------------------------------
    async searchRockyList(){

      //岩場情報ドロワーを非表示にする
      this.isDispMapRockyDrawer = false

      //登攀スタイルの絞込表示をリセットする
      this.isStyleAll = true
      this.isStyleBl = false
      this.isStyleRc = false

      //検索結果表示エリアを表示する
      let ekEl = document.getElementById('searchResult')
      ekEl.style.display = 'block'

      //テキストボックスの入力値
      let textBoxUI = document.getElementById('search-text')
      let keyword = textBoxUI.value
      //岩場をキーワードで絞込する
      let wkList = this.allRockyJsonData.filter(record => record.name.match(keyword))
      //検索結果を表示する
      if(wkList.length == 0){
        ekEl.style.fontSize = '10px'
        ekEl.innerHTML = '<b>該当する岩場は存在しません。</b>'
        return
      }else{
        ekEl.style.fontSize = '13px'
        ekEl.innerHTML = '&nbsp &nbsp<b>' +  wkList.length + '件</b> 該当しました。'
      }

      //マップ表示用のListにセットする
      let paramList = []
      wkList.forEach((target) => {
        paramList.push({
          longLat: target.longitudelatitude,
          title: target.name,
          subtitle: target.level,
          containt: target.address,
          url: target.url
        })
      })

      //表示中のマーカーを全て消す
      await this.deletAllMarker()

      //this.bounds初期化
      this.initBoundsList()

      //マーカー作成
      this.addRockyMarker(paramList,true,true,true)

    },
    //-----------------------------------------------------------
    //登攀スタイルの絞込
    //-----------------------------------------------------------
    async changeStyle(style){

      //キーワード検索と登攀スタイル絞込は連動させない。
      //キーワード検索関連の項目をクリアする
      //岩場情報ドロワーを非表示にする
      this.isDispMapRockyDrawer = false

      //検索結果表示エリアを表示する
      let ekEl = document.getElementById('searchResult')
      ekEl.style.display = 'none'

      //テキストボックスの入力値
      let textBoxUI = document.getElementById('search-text')
      textBoxUI.value = ""

      //対象のスタイルをアクティブにする
      this.isStyleAll = style == 'all' ? true : false
      this.isStyleBl = style == 'bl' ? true : false
      this.isStyleRc = style == 'rc' ? true : false
 
      //岩場情報ドロワーを非表示にする
      this.isDispMapRockyDrawer = false

      let wkList = this.allRockyJsonData

      if(style != 'all'){
        //岩場をスタイルで絞込する
        wkList = this.allRockyJsonData.filter(record => record.climbingStyleCd.match(style) || record.climbingStyleCd=='both')
      }

      //マップ表示用のListにセットする
      let paramList = []
      wkList.forEach((target) => {
        paramList.push({
          longLat: target.longitudelatitude,
          title: target.name,
          subtitle: target.level,
          containt: target.address,
          url: target.url
        })
      })

      //表示中のマーカーを全て消す
      await this.deletAllMarker()

      //this.bounds初期化
      this.initBoundsList()

      //マーカー作成
      this.addRockyMarker(paramList,true,false,false)

    },
    //表示中の全てのマーカーを削除する
    async deletAllMarker(){
      //岩場マーカー削除
      this.currentRockyMarkerList.forEach(function (marker, idx) { marker.setMap(null) })
      //岩マーカー削除
      this.currentRockMarerList.forEach(function (marker, idx) { marker.setMap(null) }) 
      //マーカークラスター削除
      if(this.markerClusterer){
        this.markerClusterer.clearMarkers()
      }
    },
    //---------------------------------------
    //MAP上に表示（コンビニ・温泉ボタン）
    //---------------------------------------
    makeCsSpaControl(){

      if(document.getElementById("csSpaControl") != null){
        //既に表示されている場合は何もしない
        return
      }

      var csSpaDiv = document.createElement('div')
      //コントロール外枠
      csSpaDiv.id = 'csSpaControl'
      csSpaDiv.style.width = '230px'
      csSpaDiv.style.padding = '5px 0'

      //コンビニ・温泉表示ボタン
      var scSpaButton = document.createElement('div')
      scSpaButton.style.height = '30px'
      scSpaButton.style.marginBottom = '0.7rem'
      scSpaButton.style.backgroundColor = '#0664a9'
      scSpaButton.style.color = '#fff'
      scSpaButton.style.cursor = 'pointer'
      scSpaButton.style.display = 'flex'
      scSpaButton.style.justifyContent = 'center'
      scSpaButton.style.alignItems = 'center'
      scSpaButton.style.borderRadius = '5px'
      scSpaButton.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px'

      scSpaButton.title = 'コンビニ・温泉を表示'
      csSpaDiv.appendChild(scSpaButton)

      var scSpaButtonText = document.createElement('div')
      scSpaButtonText.style.fontSize = '0.8rem'
      scSpaButtonText.innerHTML = '<b><i class="fas fa-map-marker-alt"></i>  コンビニ・温泉を表示</b>'
      scSpaButton.appendChild(scSpaButtonText)

      csSpaDiv.index = 2
      /* カスタムコントロールを地図の右上に配置 */
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(csSpaDiv)

      //検索ボタン押下のイベントリスナー
      google.maps.event.addDomListener(scSpaButton, "click", () => {
        //親画面のメソッド呼び出し
        this.$parent.addCsSpaOnMap()        
      })
    },
    //---------------------------------------
    //MAP上に表示（岩場に戻る）
    //---------------------------------------
    makeReturnRockyControl(){

      if(document.getElementById("returnBtnControl")){
        //既に表示されている場合は何もしない
        return
      }

      var btnDiv = document.createElement('div')
      btnDiv.id = 'returnBtnControl'
      //コントロール外枠
      btnDiv.style.padding = '3px'

      //岩場に戻る
      var returnRockyBtn = document.createElement('div')
      returnRockyBtn.style.backgroundColor = '#0664a9'
      returnRockyBtn.style.color = '#fff'
      returnRockyBtn.style.cursor = 'pointer'
      returnRockyBtn.style.display = 'flex'
      returnRockyBtn.style.justifyContent = 'center'
      returnRockyBtn.style.alignItems = 'center'   
      returnRockyBtn.style.borderRadius = '5px'
      returnRockyBtn.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px'   
      returnRockyBtn.title = '戻る'
      btnDiv.appendChild(returnRockyBtn)

      var returnRockyBtnText = document.createElement('div')
      returnRockyBtnText.style.fontSize = '0.8rem'
      returnRockyBtnText.style.padding = '5px'
      returnRockyBtnText.innerHTML = '<b><i class="fas fa-chevron-left"></i>  岩場に戻る</b>'
      returnRockyBtn.appendChild(returnRockyBtnText)

      btnDiv.index = 2
      /* カスタムコントロールを地図の左上に配置 */
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(btnDiv)

      //ボタン押下のイベントリスナー
      google.maps.event.addDomListener(returnRockyBtn, "click", () => {
        this.addRockyMarkerAndDeleteRockMarker()        
        this.$router.push('/rockyInfo/' + this.$route.params.rockyId)
        //温泉・コンビニボタンを表示する
        this.makeCsSpaControl()
        //「岩場」に戻るボタンを非表示にする
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].clear()
        //岩場画面の場合、岩場画像を表示する
        if(this.$route.name == 'rockyInfo_:rockyId'){
          this.$parent.isRockyImgShow = true
        }
      })
    },
    //---------------------------------------
    //現在位置表示ボタン
    //---------------------------------------
    makeMyLocationControl(){

      if(document.getElementById("myLocationControl")){
        //既に表示されている場合は何もしない
        return
      }

      var btnDiv = document.createElement('div')
      btnDiv.id = 'myLocationControl'

      //岩場に戻る
      var myLocationBtn = document.createElement('div')

      //地図拡大ボタンの表示(google標準or独自実装)によってstyleを分ける
      if(this.isCustumExpandCtlShow){
        //独自実装の拡大ボタンの場合
        myLocationBtn.style.width = '38px'
        myLocationBtn.style.height = '38px'
        myLocationBtn.style.margin = '50px 1.5rem 10px 10px'
        myLocationBtn.style.borderRadius = '3px'
        myLocationBtn.style.color = 'rgb(170, 170, 170)'
      }else{
        myLocationBtn.style.width = '40px'
        myLocationBtn.style.height = '40px'
        myLocationBtn.style.margin = '0 10px 10px 10px'
        if(this.$route.path == '/rocky/map_search'){
          myLocationBtn.style.margin = '10px 10px 10px 10px'
        }
        myLocationBtn.style.borderRadius = '2px'
        myLocationBtn.style.color = '#666666'
      }

      myLocationBtn.style.backgroundColor = '#FFFFFF'
      myLocationBtn.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px'
      myLocationBtn.style.border = '1px solid #cfd3d7'
      myLocationBtn.style.cursor = 'pointer'
      myLocationBtn.style.display = 'flex'
      myLocationBtn.style.justifyContent = 'center'
      myLocationBtn.style.alignItems = 'center'      
      myLocationBtn.title = '現在地'
      btnDiv.appendChild(myLocationBtn)

      var myLocationBtnText = document.createElement('div')
      myLocationBtnText.style.paddingTop = '4px'
      myLocationBtnText.innerHTML = '<b><i class="material-icons">my_location</i></b>'
      myLocationBtn.appendChild(myLocationBtnText)

      btnDiv.index = 2
      /* カスタムコントロールを地図の左上に配置 */
      this.map.controls[google.maps.ControlPosition.RIGHT].push(btnDiv)

      //ボタン押下のイベントリスナー
      google.maps.event.addDomListener(myLocationBtn, "click", () => {
        //現在地を表示する
        this.myLocationShow()        
      })
    },
    callRockyInfoFromDialog(rockyId){
      //TOP画面のMAPの中央位置とzoomの保持を有効にする
      localStorage.isLastMapCentervalid = true
      //ローカルストレージに座標情報とzoomの値をセットする
      localStorage.mapCenterLat = Math.floor( this.map.getCenter().lat() * Math.pow( 10, 6 ) ) / Math.pow( 10, 6 )
      localStorage.mapCenterLng = Math.floor( this.map.getCenter().lng() * Math.pow( 10, 6 ) ) / Math.pow( 10, 6 )
      localStorage.mapCenterZoom = this.map.getZoom()      
      this.$router.push('/rockyInfo/' + rockyId)
    },
    makeImgPath(imgPath){
      return this.getRockyThumbPath(imgPath,'360')
    }
  }
}
</script>

<style lang="stylus" scoped>
#map
  width 100%

#map-search-condition
  z-index 1
  max-width 200px

#style-condition
  z-index 1
  top 50px
  left 10px
  max-width 200px
  .btn-group-mobile
    .btn-light
      font-size 0.8rem
    .styleActive
      background #98a1a8
      color #FFFFFF
  .btn-group
    display none

#expand-button
  z-index 1
  top 0
  right 1.5rem !important
  font-size 0.5rem
  padding-top 0.5rem 

  button
    width 38px
    height 38px
    padding 0 0.5rem
    border-radius 3px
    font-size 1.2rem
    color rgb(170, 170, 170)
    background #FFF
    box-shadow rgba(0, 0, 0, 0.3) 0px 1px 4px -1px
    border solid 1px #ced4da !important

#mapRockyDrawer
  z-index 1
  bottom 0
  width 100%
  height 70px
  background #eceeef
  background-color rgba(0, 0, 0, 0.8)
  animation faderight 0.2s ease-in 1
  #mapRockyDrawer-inner
    display flex
    #rocky-img
      width 30%
      height 70px
      #rocky-img-inner
        width 100%
        height 100%
        img
          width 100%
          height 100%
          object-fit cover
        #pc-close-button
          display none
    #detail
      width 70%
      height 70px
      #top
        height 26px
        #rocky-name
          width 85%
          font-size 1rem
          font-weight 600
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
          padding 0 0 0 0.5rem
          text-decoration underline
          cursor pointer
          color #f9f9fa
          &:hover
            color #2d8fd5
        #close-button
          width 15%
          padding-top 5px
          color #FFF
          text-align center
          i
            cursor pointer
          &:hover
            color #7f8b93
      #place
        font-size 0.8rem
        height 20px
        color #dee1e3
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
        margin-top 0.1rem
        padding-left 0.5rem
      #rockyNameKana
        font-size 0.7rem
        height 20px
        color #dee1e3
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
        margin-top 0.1rem
        padding-left 0.5rem
        padding-top 1px

@keyframes faderight
  0%
    width 0px
  100%
    width 200px

// 中デバイス(415px 〜 767px)
@media (min-width: 415px)

  #style-condition
    .btn-group-mobile
      display none
    .btn-group
      max-width 200px
      display block
      .btn
        font-size 13.6px
      .styleActive
        background #98a1a8
        color #FFFFFF
  #mapRockyDrawer

    top 110px
    width 200px
    height 160px   
    margin-left 0.5rem
    #mapRockyDrawer-inner
      display block
      #rocky-img
        width 100%
        height 80px
        #rocky-img-inner
          position relative
          #pc-close-button
            display block
            width 25px
            height 25px 
            position absolute
            top 0
            right 0
            text-align center
            background #cfd3d7
            padding auto
            cursor pointer
      #detail
        width 100%
        height 60px
        #top
          height 30px
          #rocky-name
            padding-left 1rem
            font-size 0.9rem
            height 30px
          #close-button
            display none
        #place
          padding-top 0px
          padding-left 1rem
          font-size 0.8rem
        #rockyNameKana
          padding-top 1px
          padding-left 1rem
          font-size 0.7rem

  @keyframes faderight
    0%
      width 0px
    100%
      width 200px

// PC(768px～)
@media (min-width: 768px)

  #map-search-condition
    .input-group,#searchResult
      max-width 200px

  #mapRockyDrawer
    top 130px
    height 225px
    #mapRockyDrawer-inner
      #rocky-img
        height 140px

</style>
